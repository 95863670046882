<template>

    <div class="w-full h-full px-3 py-2 overflow-hidden" :class="'bg-module-'+mode">

        <div class="absolute top-0 left-0 w-full h-20 z-20" 
        :class="{'mt-24': isVisible || searchInput, '-mt-20': !isVisible && !searchInput}"
        style="transition: all .3s">
            <div class="h-full w-full flex flex-col justify-end items-center p-2" :class="'bg-module-'+mode">
                <input type="text" v-model="searchInput" :placeholder="$t('buscarusuario')" class="w-full h-10 px-2 rounded-xl" :class="'bg-box-'+mode+' text-dfont-'+mode">
            </div>
        </div>

        <div class="absolute bottom-0 right-0 h-auto flex w-auto z-30 m-2">

            <div class="h-10 w-10 rounded-full bg-red flex flex-row justify-center items-center" @click="download()">
                <i class="mdi mdi-download-outline text-white text-xl font-semibold"></i>
            </div>

            <div class="h-10 w-10 mx-2 rounded-full bg-red flex flex-row justify-center items-center" @click="openfiltersearch = true">
                <i class="mdi mdi-filter text-white text-xl font-semibold"></i>
            </div>

        </div>

        <All model="BackCompany" :immediate="true" :query="querycompanies" :data.sync="companies" v-slot="{data,loading}"></All>

        <div class="h-full w-full flex flex-col overflow-hidden">

            <div class="h-full flex flex-col relative overflow-hidden">

                <!-- <div class="h-12 w-full flex flex-row">

                    <div class="h-full w-full py-1 px-2 flex flex-row justify-between items-center">

                        <span class="text-xl font-semibold" :class="'text-dfont-'+mode">{{$t('useApp')}}</span>

                    </div>

                </div> -->

                <!-- <div class="h-auto w-full">
                    <user :data="all" @reload="reload" view="useApp" :company="companyselected"></user>
                </div> -->

                <!-- <div class="h-10 flex flex-row mt-2">

                    <div class="h-full flex flex-row justify-start items-center px-2 border-b border-darkgray mb-6" :class="{'w-8/12': searchInput != '', 'w-9/12':searchInput == '', 'bg-box-darkmode': mode == 'darkmode', 'bg-box-whitemode': mode == 'whitemode'}">
                        <i class="mdi mdi-magnify text-gray mr-2"></i>
                        <input type="text" v-model="searchInput" class="w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                    </div>

                    <div v-if="searchInput != ''" class="h-full w-1/12 flex flex-col justify-center items-center" :class="'bg-box-'+mode" @click="searchInput = ''">
                    
                        <i class="mdi  mdi-close text-red"></i>

                    </div>

                    <div class="h-full w-3/12 flex flex-row justify-end items-center">

                        <div class="h-8 w-8 rounded-lg shadow flex flex-col justify-center items-center mr-2" :class="'bg-box-'+mode" @click="openfiltersearch = true">

                            <i class="mdi mdi-filter font-semibold" :class="'text-dfont-'+mode"></i>

                        </div>

                        <All model="downloadpdfAnalitics" :immediate="false" :data.sync="excel" v-slot="{ request, loading:loadingrequest }" ref="excel">

                            <div class="h-8 w-8 rounded-lg shadow flex flex-col justify-center items-center" :class="'bg-box-'+mode" @click="download()">

                                <i class="mdi mdi-download-outline font-semibold" :class="'text-dfont-'+mode"></i>

                            </div>

                        </All>
                    
                    </div>

                </div> -->

                <div class="flex-1 overflow-hidden">

                    <All model="UsersApp" :query="query" :immediate="true" ref="users" v-slot="{data,loading}" :data.sync="users">

                        <div v-if="!loading" class="h-full overflow-auto no-scrollbar pb-12">

                            <Intersect @enter="onEnter" @leave="onLeave" >
                                <user :data="all" @reload="reload" view="useApp"></user>
                            </Intersect>

                            <user v-for="(el,index) in filteredUsers(data)" :key="index" :data="el" @reload="reload" view="useApp" :class="'bg-box-'+mode" class="rounded-lg"></user>

                            <div v-if="filteredUsers(data).length == 0"  class="h-full flex flex-col justify-center items-center">

                                <span :class="'text-dfont-'+mode">{{ $('nodata') }}</span>

                            </div>
                            

                        </div>

                        <div v-else class="h-20 relative">
                            <loader :loading="loading"></loader>
                        </div>

                    </All>

                </div>                
                
            </div>

        </div>

        <div class="absolute bottom-0 left-0 z-50 w-full p-2" :class="{'h-0': !openfiltersearch, 'h-full': openfiltersearch}" style="transition: all .3s;">

            <div v-if="openfiltersearch" class="h-full w-full rounded-lg shadow-card" :class="'bg-module-'+mode">

                <div class="h-full w-full p-2 flex flex-col">

                    <div class="h-10 flex flex-row justify-between items-center px-2">
                    
                        <span class="text-xl font-semibold" :class="'text-dfont-'+mode">{{$t('filters')}}</span>
                        <i class="mdi mdi-close text-red font-bold text-xl" @click="openfiltersearch = false"></i>
                        
                    </div>

                    <div class="flex-1 min-h-0 flex flex-col px-2">

                        <div class="h-6">
                            <span class="text-xs" :class="'text-dfont-'+mode">{{ $t('period') }}:</span>
                        </div>

                        <div class="h-8 w-full mb-2">

                            <div class="h-full rounded-lg flex flex-row justify-between items-center overflow-hidden px-2" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                 <select name="optionPeriod" v-model="optionPeriod" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                    <option v-for="(el,index) in periodos" :key="index" :value="el.value" class="text-xs" :class="'text-dfont-'+mode">{{el.name}}</option>
                                </select>
                            </div>

                        </div>

                        <div class="h-6">
                            <span class="text-xs" :class="'text-dfont-'+mode">{{ $t('buscarpor') }}:</span>
                        </div>

                        <div class="h-8 w-full mb-2">

                            <div class="h-full rounded-lg flex flex-row justify-between items-center overflow-hidden px-2" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                <select name="optiontofilter" v-model="optiontofilter" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                    <option v-for="(el,index) in options" :key="index" :value="el.value" class="text-xs" :class="'text-dfont-'+mode">{{el.name}}</option>
                                </select>
                            </div>

                        </div>

                        <div v-if="user.rol.Name != 'Gerente'" class="h-auto">

                            <All model="BackCompany" :immediate="true" :query="querycompanies" :data.sync="companies" v-slot="{data:datacompany,loading:loadingcompany}">

                                <div v-if="!loadingcompany" class="h-auto w-full">

                                    <span class="text-xs" :class="'text-dfont-'+mode">{{$t('company')}}:</span>

                                    <div class="h-8 w-full">

                                        <div class="h-full w-full rounded-lg flex flex-row justify-between items-center overflow-hidden px-2" :class="'bg-box-'+mode">

                                            <select name="companyselected2" v-model="companyselected2" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                                <option v-for="(el,index) in datacompany" :key="index" :value="el.Id" class="text-xs" :class="'text-dfont-'+mode">{{el.FrontName}}</option>
                                                <option :value="null">{{ $t('todas') }}</option>
                                            </select>

                                        </div>

                                    </div>

                                </div>

                            </All>

                        </div>

                        <div class="h-auto">
                        
                            <div class="h-auto w-full">

                                    <span class="text-xs" :class="'text-dfont-'+mode">{{ $t('rol') }}:</span>

                                    <div class="h-8 w-full">

                                        <div v-if="user.rol.Name == 'Propiedad'" class="h-full w-full rounded-lg flex flex-row justify-between items-center overflow-hidden px-2" :class="'bg-box-'+mode">

                                            <select name="rolSelected" v-model="rolSelected" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                                <option v-for="(el,index) in rols" :key="index" :value="el.value" class="text-xs" :class="'text-dfont-'+mode">{{el.name}}</option>
                                                <option :value="null">{{ $t('todos') }}</option>
                                            </select>

                                        </div>

                                        <div v-else class="h-full w-full rounded-lg flex flex-row justify-between items-center overflow-hidden px-2" :class="'bg-box-'+mode">

                                            <select name="rolSelected" v-model="rolSelected" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                                <option v-for="(el,index) in rolsNoProp" :key="index" :value="el.value" class="text-xs" :class="'text-dfont-'+mode">{{el.name}}</option>
                                                <option :value="null">{{ $t('todos') }}</option>
                                            </select>

                                        </div>

                                    </div>

                                </div>
                        
                        </div>                    

                        <div class="h-10 mt-auto mb-4">
                            <div class="h-full w-8/12 mx-auto rounded-lg bg-red flex flex-col justify-center items-center"  @click="apply()">
                                <span class="text-white font-semibold">{{ $t('apply') }}</span>
                            </div>
                        </div>

                    </div>
                    

                </div>

            </div>

        </div>

    </div>

</template>

<script>
import basiclinechart from '../../components/basiclinechart.vue';
import { All, Request } from '@/api/components';
import { state, actions } from '@/store';
import user from '../../components/user.vue';
import loader from '../../components/loader.vue';
import Intersect from 'vue-intersect';

export default {
    components:{
        basiclinechart,
        All,
        Request,
        user,
        loader,
        Intersect
    },
    data(){
        return{
            form: {
                username: null,
                email: null,
                password: null,
                password2: null,
                rol: null
            },
            rols:[
                {name:'Gerente',value:2},
                {name:'Responsable de Zona',value:3},
                {name:'Representante',value:4}
            ],
            rolsNoProp:[
                {name:'TODOS',value:null},
                {name:'Responsable de Zona',value:3},
                {name:'Representante',value:4}
            ],
            periodos:[
                {
                    name: this.$t("monthly"),
                    value: 'month'
                },
                {
                    name: this.$t("quarterly"),
                    value: 'quarter'
                },
                {
                    name: this.$t("annual"),
                    value: 'year'
                }
            ],
            searchInput: '',
            openfiltersearch: false,
            optiontofilter:'fullName',
            options:[{name:'Nombre', value: 'fullName'}, {name:'Email', value: 'email'}],
            companyselected:null,
            companyselected2:null,
            users:[],
            excel:false,
            all:{
                IdCompanies:false,
                admin:false,
                email:false,
                id:false,
                name:'Agregado',
                surname1:'de Usuarios',
                surname2:''
            },
            rolSelected:null,
            rolSelected2:null,
            companies:null,
            optionPeriod:'month',
            isVisible: false

        }
    },
    methods:{
        onLeave(){
            this.isVisible = true
        },
        onEnter(){
            this.isVisible = false
        },
        async onSuccess(){
            // this.$message({
            //     message: 'Contraseña Actualizada',
            //     type: 'success'
            // });
            // this.cambiarpass =! this.cambiarpass
            // const user = await Auth.me();
            // actions.setUser(user);

        },
        onError(){
            this.$message.error(state.messageError);
        },
        filteredUsers(data){

            if(this.companyselected != null){

                var result = [];

                let arra = Object.values(data)

                for (let index = 0; index < arra.length; index++) {

                    if(arra[index].IdCompanies){

                        var arraycompanies = arra[index].IdCompanies.split(',');

                    
                        for (let index2 = 0; index2 < arraycompanies.length; index2++) {

                            if(arraycompanies[index2].toUpperCase() == this.companyselected.toUpperCase()){

                                if(this.rolSelected2){

                                    if(arra[index].Level == this.rolSelected2){
                                        result.push(arra[index])
                                    }

                                } else {

                                    result.push(arra[index])

                                }
                                

                            }

                        }


                    } 
                    
                    
                }


                return result.filter(post => {
                    if(post[this.optiontofilter])
                    return post[this.optiontofilter].toLowerCase().includes(this.searchInput.toLowerCase())
                })


            } else {

                if(this.rolSelected2 != null){

                    var arrayResult = []

                    for (let index = 0; index < data.length; index++) {
                        
                        if(data[index].Level == this.rolSelected2){
                            arrayResult.push(data[index])
                        }
                        
                    }

                    return Object.values(arrayResult).filter(post => {
                        if(post[this.optiontofilter])
                        return post[this.optiontofilter].toLowerCase().includes(this.searchInput.toLowerCase())
                    })

                } else {                    

                    return Object.values(data).filter(post => {
                        if(post[this.optiontofilter])
                        return post[this.optiontofilter].toLowerCase().includes(this.searchInput.toLowerCase())
                    })

                }

            }

            
        },
        reload(){
            this.searchInput = ''
            this.$refs['users'].request();
        },
        createuser(){-
            this.$router.push( { name:'newuser' } )
        },
        apply(){

            if(state.user.rol.Name == 'Propiedad'){
                this.companyselected = null;
                this.companyselected = this.companyselected2;
                this.openfiltersearch = false;
            }

            
            this.rolSelected2 = this.rolSelected;
            this.openfiltersearch = false;
            

        },
        download(){

            this.loadingExcel = true
            this.axios.get('analytics/get_excel', {
                
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/vnd.ms-excel'
                },
                params:{
                    'company': this.ifgerente(),
                    'userLevel': this.rolSelected2,
                    'period': this.optionPeriod
                }
            }).then(response => {
                const blob = new Blob([response.data], { type: response.data.type });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Using_App_'+this.optionPeriod+'.xlsx');
                link.click();
                this.loadingExcel = false
            }).catch( error => {
                this.loadingExcel = 'error'
                setTimeout( () =>  {
                    this.loadingExcel = false
                }, 5000)
            })

        },
        periodname(periodo){

            switch (periodo) {
                case 'month':
                    return 'Mensual'
                    break;
                case 'quarter':
                    return 'Trimestral'
                    break;
                case 'year':
                    return 'Anual'
                    break;
            }

        },
        ifgerente(){
            if(state.user.rol.Name == 'Gerente'){
                return state.user.relation[0].IdCompany
            } else {
                return this.companyselected2
            }
        }

    },
    computed:{
        user(){
            return state.user
        },
        querycompanies(){
            return{
                period: state.period,
                Dimension: state.dimensionGroup
            }
        },
        query(){

            if(state.user.rol.Name == 'Gerente'){

                return {

                    company: state.idCompany

                }

            }

        },
        mode(){
            return state.mode
        }

    },
    watch:{
        companyselected(n,o){

            if(this.companies){

                for (let index = 0; index < this.companies.length; index++) {
                    
                    if(this.companies[index].Id == n){

                        actions.setCompanyAnalitycs(this.companies[index])

                    }
                    
                }

            }

        },
        companies(n,o){

            if(this.companyselected){

                for (let index = 0; index < n.length; index++) {
                        
                    if(n[index].Id == this.companyselected){

                        actions.setCompanyAnalitycs(n[index])

                    }
                    
                }
            }

        },
        rolSelected2(n,o){
            this.$refs['users'].request();
        }
    },
    mounted(){

        if(state.user.rol.Name == 'Gerente'){

            this.companyselected = state.idCompany.toLowerCase()
            actions.setCompanyAnalitycs(state.user.relation[0].company)
            

        }

    }
}
</script>